/*!
 * Kno2 Bootstrap v3.0.0 Overrides
 */

.lead {
    font-size: 21px;
}

.page-header {
    border-bottom: 0 none;
}

.dl-horizontal dt {
    float: left;
    width: 160px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dl-horizontal dd {
    margin-left: 180px;
}

.dl-horizontal dd:before,
.dl-horizontal dd:after {
    display: table;
    content: " ";
}

.dl-horizontal dd:after {
    clear: both;
}

.container {
    max-width: 750px;
}

.p-6 {
    padding: calc(1rem * 6);
}

.px-6 {
    padding-left: calc(1rem * 6);
    padding-right: calc(1rem * 6);
}

.pl-6 {
    padding-left: calc(1rem * 6);
}

.pr-6 {
    padding-right: calc(1rem * 6);
}

.py-6 {
    padding-top: calc(1rem * 6);
    padding-bottom: calc(1rem * 6);
}

.pt-6 {
    padding-top: calc(1rem * 6);
}

.pb-6 {
    padding-bottom: calc(1rem * 6);
}

.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11 {
    float: left;
}

.col-sm-1 {
    width: 8.333333333333332%;
}

.col-sm-2 {
    width: 16.666666666666664%;
}

.col-sm-3 {
    width: 25%;
}

.col-sm-4 {
    width: 33.33333333333333%;
}

.col-sm-5 {
    width: 41.66666666666667%;
}

.col-sm-6 {
    width: 50%;
}

.col-sm-7 {
    width: 58.333333333333336%;
}

.col-sm-8 {
    width: 66.66666666666666%;
}

.col-sm-9 {
    width: 75%;
}

.col-sm-10 {
    width: 83.33333333333334%;
}

.col-sm-11 {
    width: 91.66666666666666%;
}

.col-sm-12 {
    width: 100%;
}

.col-sm-push-1 {
    left: 8.333333333333332%;
}

.col-sm-push-2 {
    left: 16.666666666666664%;
}

.col-sm-push-3 {
    left: 25%;
}

.col-sm-push-4 {
    left: 33.33333333333333%;
}

.col-sm-push-5 {
    left: 41.66666666666667%;
}

.col-sm-push-6 {
    left: 50%;
}

.col-sm-push-7 {
    left: 58.333333333333336%;
}

.col-sm-push-8 {
    left: 66.66666666666666%;
}

.col-sm-push-9 {
    left: 75%;
}

.col-sm-push-10 {
    left: 83.33333333333334%;
}

.col-sm-push-11 {
    left: 91.66666666666666%;
}

.col-sm-pull-1 {
    right: 8.333333333333332%;
}

.col-sm-pull-2 {
    right: 16.666666666666664%;
}

.col-sm-pull-3 {
    right: 25%;
}

.col-sm-pull-4 {
    right: 33.33333333333333%;
}

.col-sm-pull-5 {
    right: 41.66666666666667%;
}

.col-sm-pull-6 {
    right: 50%;
}

.col-sm-pull-7 {
    right: 58.333333333333336%;
}

.col-sm-pull-8 {
    right: 66.66666666666666%;
}

.col-sm-pull-9 {
    right: 75%;
}

.col-sm-pull-10 {
    right: 83.33333333333334%;
}

.col-sm-pull-11 {
    right: 91.66666666666666%;
}

.col-sm-offset-1 {
    margin-left: 8.333333333333332%;
}

.col-sm-offset-2 {
    margin-left: 16.666666666666664%;
}

.col-sm-offset-3 {
    margin-left: 25%;
}

.col-sm-offset-4 {
    margin-left: 33.33333333333333%;
}

.col-sm-offset-5 {
    margin-left: 41.66666666666667%;
}

.col-sm-offset-6 {
    margin-left: 50%;
}

.col-sm-offset-7 {
    margin-left: 58.333333333333336%;
}

.col-sm-offset-8 {
    margin-left: 66.66666666666666%;
}

.col-sm-offset-9 {
    margin-left: 75%;
}

.col-sm-offset-10 {
    margin-left: 83.33333333333334%;
}

.col-sm-offset-11 {
    margin-left: 91.66666666666666%;
}

@media (min-width: 992px) {
    .container {
        max-width: 970px;
    }

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11 {
        float: left;
    }

    .col-md-1 {
        width: 8.333333333333332%;
    }

    .col-md-2 {
        width: 16.666666666666664%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33333333333333%;
    }

    .col-md-5 {
        width: 41.66666666666667%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.333333333333336%;
    }

    .col-md-8 {
        width: 66.66666666666666%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.33333333333334%;
    }

    .col-md-11 {
        width: 91.66666666666666%;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-push-0 {
        left: auto;
    }

    .col-md-push-1 {
        left: 8.333333333333332%;
    }

    .col-md-push-2 {
        left: 16.666666666666664%;
    }

    .col-md-push-3 {
        left: 25%;
    }

    .col-md-push-4 {
        left: 33.33333333333333%;
    }

    .col-md-push-5 {
        left: 41.66666666666667%;
    }

    .col-md-push-6 {
        left: 50%;
    }

    .col-md-push-7 {
        left: 58.333333333333336%;
    }

    .col-md-push-8 {
        left: 66.66666666666666%;
    }

    .col-md-push-9 {
        left: 75%;
    }

    .col-md-push-10 {
        left: 83.33333333333334%;
    }

    .col-md-push-11 {
        left: 91.66666666666666%;
    }

    .col-md-pull-0 {
        right: auto;
    }

    .col-md-pull-1 {
        right: 8.333333333333332%;
    }

    .col-md-pull-2 {
        right: 16.666666666666664%;
    }

    .col-md-pull-3 {
        right: 25%;
    }

    .col-md-pull-4 {
        right: 33.33333333333333%;
    }

    .col-md-pull-5 {
        right: 41.66666666666667%;
    }

    .col-md-pull-6 {
        right: 50%;
    }

    .col-md-pull-7 {
        right: 58.333333333333336%;
    }

    .col-md-pull-8 {
        right: 66.66666666666666%;
    }

    .col-md-pull-9 {
        right: 75%;
    }

    .col-md-pull-10 {
        right: 83.33333333333334%;
    }

    .col-md-pull-11 {
        right: 91.66666666666666%;
    }

    .col-md-offset-0 {
        margin-left: 0;
    }

    .col-md-offset-1 {
        margin-left: 8.333333333333332%;
    }

    .col-md-offset-2 {
        margin-left: 16.666666666666664%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }

    .col-md-offset-4 {
        margin-left: 33.33333333333333%;
    }

    .col-md-offset-5 {
        margin-left: 41.66666666666667%;
    }

    .col-md-offset-6 {
        margin-left: 50%;
    }

    .col-md-offset-7 {
        margin-left: 58.333333333333336%;
    }

    .col-md-offset-8 {
        margin-left: 66.66666666666666%;
    }

    .col-md-offset-9 {
        margin-left: 75%;
    }

    .col-md-offset-10 {
        margin-left: 83.33333333333334%;
    }

    .col-md-offset-11 {
        margin-left: 91.66666666666666%;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }

    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11 {
        float: left;
    }

    .col-lg-1 {
        width: 8.333333333333332%;
    }

    .col-lg-2 {
        width: 16.666666666666664%;
    }

    .col-lg-3 {
        width: 25%;
    }

    .col-lg-4 {
        width: 33.33333333333333%;
    }

    .col-lg-5 {
        width: 41.66666666666667%;
    }

    .col-lg-6 {
        width: 50%;
    }

    .col-lg-7 {
        width: 58.333333333333336%;
    }

    .col-lg-8 {
        width: 66.66666666666666%;
    }

    .col-lg-9 {
        width: 75%;
    }

    .col-lg-10 {
        width: 83.33333333333334%;
    }

    .col-lg-11 {
        width: 91.66666666666666%;
    }

    .col-lg-12 {
        width: 100%;
    }

    .col-lg-push-0 {
        left: auto;
    }

    .col-lg-push-1 {
        left: 8.333333333333332%;
    }

    .col-lg-push-2 {
        left: 16.666666666666664%;
    }

    .col-lg-push-3 {
        left: 25%;
    }

    .col-lg-push-4 {
        left: 33.33333333333333%;
    }

    .col-lg-push-5 {
        left: 41.66666666666667%;
    }

    .col-lg-push-6 {
        left: 50%;
    }

    .col-lg-push-7 {
        left: 58.333333333333336%;
    }

    .col-lg-push-8 {
        left: 66.66666666666666%;
    }

    .col-lg-push-9 {
        left: 75%;
    }

    .col-lg-push-10 {
        left: 83.33333333333334%;
    }

    .col-lg-push-11 {
        left: 91.66666666666666%;
    }

    .col-lg-pull-0 {
        right: auto;
    }

    .col-lg-pull-1 {
        right: 8.333333333333332%;
    }

    .col-lg-pull-2 {
        right: 16.666666666666664%;
    }

    .col-lg-pull-3 {
        right: 25%;
    }

    .col-lg-pull-4 {
        right: 33.33333333333333%;
    }

    .col-lg-pull-5 {
        right: 41.66666666666667%;
    }

    .col-lg-pull-6 {
        right: 50%;
    }

    .col-lg-pull-7 {
        right: 58.333333333333336%;
    }

    .col-lg-pull-8 {
        right: 66.66666666666666%;
    }

    .col-lg-pull-9 {
        right: 75%;
    }

    .col-lg-pull-10 {
        right: 83.33333333333334%;
    }

    .col-lg-pull-11 {
        right: 91.66666666666666%;
    }

    .col-lg-offset-0 {
        margin-left: 0;
    }

    .col-lg-offset-1 {
        margin-left: 8.333333333333332%;
    }

    .col-lg-offset-2 {
        margin-left: 16.666666666666664%;
    }

    .col-lg-offset-3 {
        margin-left: 25%;
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333333333%;
    }

    .col-lg-offset-5 {
        margin-left: 41.66666666666667%;
    }

    .col-lg-offset-6 {
        margin-left: 50%;
    }

    .col-lg-offset-7 {
        margin-left: 58.333333333333336%;
    }

    .col-lg-offset-8 {
        margin-left: 66.66666666666666%;
    }

    .col-lg-offset-9 {
        margin-left: 75%;
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333333334%;
    }

    .col-lg-offset-11 {
        margin-left: 91.66666666666666%;
    }
}

table {
    background-color: white;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border: 1px solid #dddddd !important;
}

.form-control {
    border-radius: 2px;
}

.form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}

.form-inline .form-control {
    display: inline-block;
}

.form-inline .radio,
.form-inline .checkbox {
    display: inline-block;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
    float: none;
    margin-left: 0;
}

.glyphicon {
    top: auto;
}

.navbar-right .dropdown-menu {
    right: 0;
    left: auto;
}

.btn-group .btn-primary.dropdown-toggle {
    border-left: 0 none;
}

.btn-group > .btn:first-child {
    margin-left: 5px;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0 2px 2px 0;
}

.input-group-addon:first-child {
    border-radius: 2px 0 0 2px;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background-color: #555;
}

.nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
}

.nav-justified > li {
    display: table-cell;
    width: 1%;
}

.navbar {
    border-radius: 4px;
}

.navbar-header {
    float: left;
}

.navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none;
}

.navbar-collapse.collapse {
    display: block !important;
    padding-bottom: 0;
    overflow: visible !important;
}

.navbar-collapse.in {
    overflow-y: visible;
}

.navbar-collapse .navbar-nav.navbar-left:first-child {
    margin-left: -15px;
}

.navbar-collapse .navbar-nav.navbar-right:last-child {
    margin-right: -15px;
}

.navbar-collapse .navbar-text:last-child {
    margin-right: 0;
}

.container > .navbar-header,
.container > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
}

.navbar-static-top {
    border-radius: 0;
}

.navbar-fixed-top,
.navbar-fixed-bottom {
    border-radius: 0;
}

.navbar > .container .navbar-brand {
    margin-left: -15px;
}

.navbar-toggle {
    display: none;
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
        position: absolute;
        float: left;
        width: auto;
        margin: 2px 0 0;
        background-color: #ffffff;
        border: 1px solid #cccccc;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }

    .navbar-nav .open .dropdown-menu > li > a,
    .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 3px 20px;
    }

    .navbar-nav .open .dropdown-menu > li > a {
        line-height: 1.428571429;
    }

    .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-nav .open .dropdown-menu > li > a:focus {
        background-image: none;
    }
}

.navbar-nav {
    float: left;
    margin: 0;
}

.navbar-nav > li {
    float: left;
}

.navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
}

.navbar-left {
    float: left !important;
}

.navbar-right {
    float: right !important;
}

.navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}

.navbar-form .form-control {
    display: inline-block;
}

.navbar-form .radio,
.navbar-form .checkbox {
    display: inline-block;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.navbar-form .radio input[type="radio"],
.navbar-form .checkbox input[type="checkbox"] {
    float: none;
    margin-left: 0;
}

@media (max-width: 767px) {
    .navbar-form .form-group {
        margin-bottom: 0;
    }
}

.navbar-form {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.navbar-text {
    margin-right: 15px;
    margin-left: 15px;
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #333333;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #ffffff;
        background-color: #428bca;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #ffffff;
        background-color: #428bca;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
        color: #999999;
        background-color: transparent;
    }
}

@media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
        color: #999999;
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
        color: #333333;
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #ffffff;
        background-color: #428bca;
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #ffffff;
        background-color: #428bca;
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
        color: #999999;
        background-color: transparent;
    }
}

.jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;
}

.container .jumbotron {
    padding-right: 60px;
    padding-left: 60px;
}

.jumbotron h1 {
    font-size: 63px;
}

.modal-header i.box-info {
    padding: 0;
}

.modal-title span {
    margin-left: 10px;
}

.modal-dialog {
    right: auto;
    left: 50%;
    width: 600px;
}

.modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.popover {
    z-index: 11000;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    font-family: serif;
    font-size: 30px;
    line-height: 24px;
}

.carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
}

.carousel-indicators {
    bottom: 20px;
}

.visible-xs {
    display: block !important;
}

tr.visible-xs {
    display: table-row !important;
}

th.visible-xs,
td.visible-xs {
    display: table-cell !important;
}

.visible-sm.visible-xs {
    display: block !important;
}

tr.visible-sm.visible-xs {
    display: table-row !important;
}

th.visible-sm.visible-xs,
td.visible-sm.visible-xs {
    display: table-cell !important;
}

.visible-md.visible-xs {
    display: block !important;
}

tr.visible-md.visible-xs {
    display: table-row !important;
}

th.visible-md.visible-xs,
td.visible-md.visible-xs {
    display: table-cell !important;
}

.visible-lg.visible-xs {
    display: block !important;
}

tr.visible-lg.visible-xs {
    display: table-row !important;
}

th.visible-lg.visible-xs,
td.visible-lg.visible-xs {
    display: table-cell !important;
}

.hidden-xs {
    display: none !important;
}

tr.hidden-xs {
    display: none !important;
}

th.hidden-xs,
td.hidden-xs {
    display: none !important;
}

.hidden-sm.hidden-xs {
    display: none !important;
}

tr.hidden-sm.hidden-xs {
    display: none !important;
}

th.hidden-sm.hidden-xs,
td.hidden-sm.hidden-xs {
    display: none !important;
}

.hidden-md.hidden-xs {
    display: none !important;
}

tr.hidden-md.hidden-xs {
    display: none !important;
}

th.hidden-md.hidden-xs,
td.hidden-md.hidden-xs {
    display: none !important;
}

.hidden-lg.hidden-xs {
    display: none !important;
}

tr.hidden-lg.hidden-xs {
    display: none !important;
}

th.hidden-lg.hidden-xs,
td.hidden-lg.hidden-xs {
    display: none !important;
}

@media (max-width: 991px) {
    .visible-xs.visible-sm {
        display: block !important;
    }

    tr.visible-xs.visible-sm {
        display: table-row !important;
    }

    th.visible-xs.visible-sm,
    td.visible-xs.visible-sm {
        display: table-cell !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-xs.visible-md {
        display: block !important;
    }

    tr.visible-xs.visible-md {
        display: table-row !important;
    }

    th.visible-xs.visible-md,
    td.visible-xs.visible-md {
        display: table-cell !important;
    }
}

@media (min-width: 1200px) {
    .visible-xs.visible-lg {
        display: block !important;
    }

    tr.visible-xs.visible-lg {
        display: table-row !important;
    }

    th.visible-xs.visible-lg,
    td.visible-xs.visible-lg {
        display: table-cell !important;
    }
}

@media (max-width: 991px) {
    hid .visible-sm {
        display: block !important;
    }

    tr.visible-sm {
        display: table-row !important;
    }

    th.visible-sm,
    td.visible-sm {
        display: table-cell !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-sm.visible-md {
        display: block !important;
    }

    tr.visible-sm.visible-md {
        display: table-row !important;
    }

    th.visible-sm.visible-md,
    td.visible-sm.visible-md {
        display: table-cell !important;
    }
}

@media (min-width: 1200px) {
    .visible-sm.visible-lg {
        display: block !important;
    }

    tr.visible-sm.visible-lg {
        display: table-row !important;
    }

    th.visible-sm.visible-lg,
    td.visible-sm.visible-lg {
        display: table-cell !important;
    }
}

@media (max-width: 991px) {
    .visible-md.visible-sm {
        display: block !important;
    }

    tr.visible-md.visible-sm {
        display: table-row !important;
    }

    th.visible-md.visible-sm,
    td.visible-md.visible-sm {
        display: table-cell !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
        display: block !important;
    }

    tr.visible-md {
        display: table-row !important;
    }

    th.visible-md,
    td.visible-md {
        display: table-cell !important;
    }
}

@media (min-width: 1200px) {
    .visible-md.visible-lg {
        display: block !important;
    }

    tr.visible-md.visible-lg {
        display: table-row !important;
    }

    th.visible-md.visible-lg,
    td.visible-md.visible-lg {
        display: table-cell !important;
    }
}

@media (max-width: 991px) {
    .visible-lg.visible-sm {
        display: block !important;
    }

    tr.visible-lg.visible-sm {
        display: table-row !important;
    }

    th.visible-lg.visible-sm,
    td.visible-lg.visible-sm {
        display: table-cell !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-lg.visible-md {
        display: block !important;
    }

    tr.visible-lg.visible-md {
        display: table-row !important;
    }

    th.visible-lg.visible-md,
    td.visible-lg.visible-md {
        display: table-cell !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
    }

    tr.visible-lg {
        display: table-row !important;
    }

    th.visible-lg,
    td.visible-lg {
        display: table-cell !important;
    }
}

@media (max-width: 991px) {
    .hidden-xs.hidden-sm {
        display: none !important;
    }

    tr.hidden-xs.hidden-sm {
        display: none !important;
    }

    th.hidden-xs.hidden-sm,
    td.hidden-xs.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-xs.hidden-md {
        display: none !important;
    }

    tr.hidden-xs.hidden-md {
        display: none !important;
    }

    th.hidden-xs.hidden-md,
    td.hidden-xs.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-xs.hidden-lg {
        display: none !important;
    }

    tr.hidden-xs.hidden-lg {
        display: none !important;
    }

    th.hidden-xs.hidden-lg,
    td.hidden-xs.hidden-lg {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }

    tr.hidden-sm {
        display: none !important;
    }

    th.hidden-sm,
    td.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-sm.hidden-md {
        display: none !important;
    }

    tr.hidden-sm.hidden-md {
        display: none !important;
    }

    th.hidden-sm.hidden-md,
    td.hidden-sm.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-sm.hidden-lg {
        display: none !important;
    }

    tr.hidden-sm.hidden-lg {
        display: none !important;
    }

    th.hidden-sm.hidden-lg,
    td.hidden-sm.hidden-lg {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .hidden-md.hidden-sm {
        display: none !important;
    }

    tr.hidden-md.hidden-sm {
        display: none !important;
    }

    th.hidden-md.hidden-sm,
    td.hidden-md.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }

    tr.hidden-md {
        display: none !important;
    }

    th.hidden-md,
    td.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-md.hidden-lg {
        display: none !important;
    }

    tr.hidden-md.hidden-lg {
        display: none !important;
    }

    th.hidden-md.hidden-lg,
    td.hidden-md.hidden-lg {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .hidden-lg.hidden-sm {
        display: none !important;
    }

    tr.hidden-lg.hidden-sm {
        display: none !important;
    }

    th.hidden-lg.hidden-sm,
    td.hidden-lg.hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-lg.hidden-md {
        display: none !important;
    }

    tr.hidden-lg.hidden-md {
        display: none !important;
    }

    th.hidden-lg.hidden-md,
    td.hidden-lg.hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }

    tr.hidden-lg {
        display: none !important;
    }

    th.hidden-lg,
    td.hidden-lg {
        display: none !important;
    }
}
